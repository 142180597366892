'use client'
import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import React, { useEffect } from "react";
import getCookieRunData from '@/public/cookie-consent'
import useUserInteraction from "@/app/hooks/useUserInteraction";
import {useTracking} from "@/app/contexts/tracking-context";
import Script from "next/script";

export default function ClientHandler() {
    const { setIsGtagLoaded } = useTracking();

    useEffect(() => {
        CookieConsent.run(getCookieRunData())
    }, []);

    useUserInteraction('mousemove', function() {
        CookieConsent.show()
    }, { withSingleTrigger: true })

    return <>
        <Script
            src="https://reassurez-moi.fr/shared-tracking.js"
            strategy="lazyOnload"
            onLoad={() => setTimeout(() => setIsGtagLoaded(true), 1000)}
        />
    </>
}
